body {
  padding: 5%;
  background-color: #F5F5F5;
}

.container {
  padding:0;
  background-color: #FFF; 
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
  height: 700px;
}

/* ===== MENU ===== */
.menu {
  float: left;
  height: 700px;;
  width: 70px;
  background: #4768b5;
  background: -webkit-linear-gradient(#4768b5, #35488e);
  background: -o-linear-gradient(#4768b5, #35488e);
  background: -moz-linear-gradient(#4768b5, #35488e);
  background: linear-gradient(#4768b5, #35488e);
  box-shadow: 0 10px 20px rgba(0,0,0,0.19);
}

.menu .items {
  list-style:none;
  margin: auto;
  padding: 0;
}

.menu .items .item {
  height: 70px;
  border-bottom: 1px solid #6780cc;
  display:flex;
  justify-content: center;
  align-items: center;
  color: #9fb5ef;
  font-size: 17pt;
}

.menu .items .item-active {
  background-color:#5172c3;
  color: #FFF;
}

.menu .items .item:hover {
  cursor: pointer;
  background-color: #4f6ebd;
  color: #cfe5ff;
}

/* === CONVERSATIONS === */

.discussions {
  width: 35%;
  height: 700px;
  box-shadow: 0px 8px 10px rgba(0,0,0,0.20);
  overflow: hidden;
  display: inline-block;
}

.discussions .discussion {
  width: 100%;
  height: 90px;
  background-color: #FAFAFA;
  border-bottom: solid 1px #E0E0E0;
  display:flex;
  align-items: center;
  cursor: pointer;
}

.discussions .search {
  display:flex;
  align-items: center;
  justify-content: center;
  color: #E0E0E0;
}

.discussions .search .searchbar {
  height: 40px;
  background-color: #FFF;
  width: 70%;
  padding: 0 20px;
  border-radius: 50px;
  border: 1px solid #EEEEEE;
  display:flex;
  align-items: center;
  cursor: pointer;
}

.discussions .search .searchbar input {
  margin-left: 15px;
  height:38px;
  width:100%;
  border:none;
  font-family: 'Montserrat', sans-serif;;
}

.discussions .search .searchbar *::-webkit-input-placeholder {
    color: #E0E0E0;
}
.discussions .search .searchbar input *:-moz-placeholder {
    color: #E0E0E0;
}
.discussions .search .searchbar input *::-moz-placeholder {
    color: #E0E0E0;
}
.discussions .search .searchbar input *:-ms-input-placeholder {
    color: #E0E0E0;
}

.discussions .message-active {
  width: 98.5%;
  height: 90px;
  background-color: #FFF;
  border-bottom: solid 1px #E0E0E0;
  color: #87a3ec;
  width: 100%;
  border-right: 5px solid #87a3ec;
}

.discussions .discussion .photo {
    margin-left:20px;
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.online {
  position: relative;
  top: 30px;
  left: 35px;
  width: 13px;
  height: 13px;
  background-color: #8BC34A;
  border-radius: 13px;
  border: 3px solid #FAFAFA;
}

.desc-contact {
  height: 43px;
  width:50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.discussions .discussion .name {
  margin: 0 0 0 20px;
  font-family:'Montserrat', sans-serif;
  font-size: 11pt;
  color:#515151;
}

.discussions .discussion .message {
  margin: 6px 0 0 20px;
  font-family:'Montserrat', sans-serif;
  font-size: 9pt;
  color:#515151;
}

.timer {
  margin-left: 15%;
  font-family:'Open Sans', sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #BBB;
  background-color: #FFF;
  border: 1px solid #E5E5E5;
  border-radius: 15px;
}

.add {
  margin-left: 15%;
  font-family: 'Open Sans', sans-serif;
  font-size: 11px;
  padding: 3px 8px;
  color: #ffffff;
  background-color: #6780cc;
  border: 1px solid #e5e5e500;
  border-radius: 15px;
}

.add:hover{
  opacity: 0.8;
}

.chat {
  display: flex;
  flex-direction: column;
  max-height: 700px;
  width: calc(65% - 85px);
}

.header-chat {
  background-color: #FFF;
  height: 90px;
  box-shadow: 0px 3px 2px rgba(0,0,0,0.100);
  display: flex;
  align-items: center;
  flex-shrink: 0;
}

.chat .header-chat .icon {
  margin-left: 30px;
  color:#515151;
  font-size: 14pt;
}

.chat .header-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family:'Montserrat', sans-serif;
  font-size: 13pt;
  color:#515151;
}

.chat .header-chat .right {
  position: absolute;
  right: 40px;
}

.chat .messages-chat {
  padding: 25px 35px;
  flex-grow: 1;
  overflow-y: auto;
}

.chat .messages-chat .message {
  display:flex;
  align-items: center;
  margin-bottom: 8px;
}

.chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.chat .messages-chat .text {
  margin: 0 35px;
  background-color: #f6f6f6;
  padding: 15px;
  border-radius: 12px;
}

.text-only {
  margin-left: 45px;
}

.time {
  font-size: 10px;
  color:lightgrey;
  margin-bottom:10px;
  margin-left: 85px;
}

.response-time {
  float: right;
  margin-right: 40px !important;
}

.response {
  float: right;
  margin-right: 0px !important;
  margin-left:auto; /* flexbox alignment rule */
}

.response .text {
  background-color: #e3effd !important;
}

.footer-chat {
  height: 80px;
  display:flex;
  align-items: center;
  background-color: transparent;
  border-top: 2px solid #EEE;
  flex-shrink: 0;
  
}

.chat .footer-chat .icon {
  margin-left: 30px;
  color:#C0C0C0;
  font-size: 14pt;
}

.chat .footer-chat .send {
  color:#fff;
  background-color: #4f6ebd;
  position: absolute;
  right: 50px;
  padding: 12px 12px 12px 12px;
  border-radius: 50px;
  font-size: 14pt;
}

.chat .footer-chat .name {
  margin: 0 0 0 20px;
  text-transform: uppercase;
  font-family:'Montserrat', sans-serif;
  font-size: 13pt;
  color:#515151;
}

.chat .footer-chat .right {
  position: absolute;
  right: 40px;
}

.write-message {
  border:none !important;
  width:60%;
  height: 50px;
  margin-left: 20px;
  padding: 10px;
}

.footer-chat *::-webkit-input-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
}
.footer-chat input *:-moz-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
}
.footer-chat input *::-moz-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
  margin-left:5px;
}
.footer-chat input *:-ms-input-placeholder {
  color: #C0C0C0;
  font-size: 13pt;
}

.clickable {
  cursor: pointer;
}

.message#you{
  background-color: #61dafb;
}
.message#notyou{
  background-color: greenyellow;
}

.wrapper {
  margin-top: 80px;
  margin-bottom: 80px;
  flex: 1;
}

.form-signin {
  max-width: 380px;
  padding: 15px 35px 45px;
  margin: 0 auto;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.form-signin .form-signin-heading,
.form-signin .checkbox {
  margin-bottom: 30px;
}
.form-signin .checkbox {
  font-weight: normal;
  margin-bottom: 0.5rem;
}
.form-signin .form-control {
  margin-bottom: -1px;
  position: relative;
  font-size: 16px;
  height: auto;
  padding: 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type=text] {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.form-signin input[type=password]:last-of-type {
  margin-bottom: 20px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.span-register{
  cursor:  pointer;
  text-decoration:  underline; 
  color: #0275d8;
}

.span-register:hover{
  opacity: 0.6;
}

.card {
  float: left;
  margin: 3rem;
}

.card-one {
  position: relative;
  width: 300px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 12px 28px 0px, rgba(0, 0, 0, 0.1) 0px 2px 4px 0px, rgba(255, 255, 255, 0.05) 0px 0px 0px 1px inset;
}
.card-one header {
  position: relative;
  width: 100%;
  height: 60px;
  background-color: #c8c;
}
.card-one header::before, .card-one header::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: inherit;
}
.card-one header::before {
  transform: skewY(-8deg);
  transform-origin: 100% 100%;
}
.card-one header::after {
  transform: skewY(8deg);
  transform-origin: 0 100%;
}
.card-one header .avatar {
  cursor: pointer;
  position: absolute;
  left: 50%;
  top: 30px;
  margin-left: -50px;
  z-index: 5;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background: #ccc;
  border: 3px solid #fff;
}
.card-one header .avatar img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100px;
  height: auto;
}
.card-one h3 {
  position: relative;
  margin: 80px 0 30px;
  text-align: center;
}
.card-one h3::after {
  content: "";
  position: absolute;
  bottom: -15px;
  left: 50%;
  margin-left: -15px;
  width: 30px;
  height: 1px;
  background: #000;
}
.card-one .desc {
  padding: 0 1rem 2rem;
  text-align: center;
  line-height: 1.5;
  color: #777;
}
.card-one .contacts {
  width: 200px;
  max-width: 100%;
  margin: 0 auto 3rem;
}
.card-one .contacts a {
  display: block;
  width: 33.333333%;
  float: left;
  text-align: center;
  color: #c8c;
}
.card-one .contacts a:hover {
  color: #333;
}
.card-one .contacts a:hover .fa::before {
  color: #fff;
}
.card-one .contacts a:hover .fa::after {
  width: 100%;
  height: 100%;
}
.card-one .contacts a .fa {
  position: relative;
  width: 40px;
  height: 40px;
  line-height: 39px;
  overflow: hidden;
  text-align: center;
  font-size: 1.3em;
}
.card-one .contacts a .fa:before {
  position: relative;
  z-index: 1;
}
.card-one .contacts a .fa::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0;
  height: 0;
  transform: translate(-50%, -50%);
  background: #c8c;
  transition: width 0.3s, height 0.3s;
}
.card-one .contacts a:last-of-type .fa {
  line-height: 36px;
}
.card-one footer {
  position: relative;
  padding: 1rem;
  background-color: #6573d0;
  text-align: center;
}
.card-one footer a {
  padding: 0 1rem;
  color: #e2e2e2;
  transition: color 0.4s;
}
.card-one footer a:hover {
  color: #c8c;
}
.card-one footer::before {
  content: "";
  position: absolute;
  top: -27px;
  left: 50%;
  margin-left: -15px;
  border: 15px solid transparent;
  border-bottom-color: #6573d0;
}

.cards-container {
  width: 793px;
  max-width: 100%;
  margin: 2rem auto;
  flex: 0;
}

.desc textarea::placeholder{
  color: rgb(197, 194, 194);
}

.desc textarea{
  overflow: hidden;
  resize: none;
}